<template>
  <div class="page-content" v-if="user">
    <page-breadcrumb
      title="User Detail"
      :items="breadcrumbItems"
    />
    <b-card class="mt-3">
      <!-- edit buttons -->
      <div class="d-flex justify-content-end">
        <!-- <b-button class="btn-icon" @click="editAble">
          <feather-icon :icon="!isEditing ? 'EditIcon' : 'XIcon'" />
        </b-button> -->
        <!-- <b-button v-if="isEditing" class="btn-icon ml-50" variant="primary" @click="updateUserEmail">
          <b-spinner v-if="isLoading" small class="mr-50" />
          <feather-icon v-else icon="CheckIcon"/>
        </b-button> -->
        <delete-button v-if="userData.role === 'admin'" class="btn-icon ml-50" variant="relief-danger" @delete="suspendUser"
          v-b-tooltip.hover="'suspend user'"
        >
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="SlashIcon" />
        </delete-button>
      </div>
      <!-- edit buttons end -->

      <b-media class="mt-1">
        <template #aside>
          <b-avatar
            rounded
            size="60"
            :src="user.avatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <feather-icon
              v-if="!user.email"
              icon="UserIcon"
              size="60"
            />
          </b-avatar>
        </template>
        <b-card-text class="d-flex">
          <div class="mr-3">
            <span style="display: block">Name: {{ user.name }}</span>
            <span class="mt-1" style="display: block">Email: {{ user.email }}</span>
            <span class="mt-1" style="display: block">Suzu ID: {{ user.user_id }}</span>
            <span class="mt-1" style="display: block">Current Language: {{ user.language }}</span>
          </div>
        </b-card-text>
      </b-media>

      <div class="mt-3">
        <!-- Entry test result -->
        <h4>Entry Test Result</h4>
        <data-table-ssr id="item_list" ref="item_list"
          :get-list-fn="getEntryTestResult"
          :allow_pagination="false"
          :columns="exam_table_fields"
        />
        <!-- End Entry test result -->

        <!-- User Learning Summary -->
        <h4>User Learning Summary</h4>
        <data-table-ssr id="item_list" ref="item_list"
          :get-list-fn="getUserLearningSummary"
          :allow_pagination="false"
          :columns="learning_summary_fields"
        />
      </div>
    </b-card>
    <action-buttons back-button />
    <b-card v-if="user.role === 2">
      <h2 class="h2">Teacher Info</h2>
      <div>
        <b-form-group label="About me" label-for="about_me">
          <b-form-input v-model="user.teacher_description.about_me" id="about_me" />
        </b-form-group>
        <b-form-group label="Education" label-for="education">
          <b-form-input v-model="user.teacher_description.education" id="education" />
        </b-form-group>
        <b-form-group label="Experience" label-for="experience">
          <b-form-input v-model="user.teacher_description.experience" id="experience" />
        </b-form-group>
        <b-button variant="success" style="float: right" @click="updateTeacherDescription">Update</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import service from "../service";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {STATUS} from './../constants.js';

let english_level_opts = {};
const exam_table_fields = [
  { label: 'Date (Taken Entry Test)', field: 'createdAt', value_handler: (model)=>{let date = new Date(model.createdAt); return date.toISOString().split('T')[0]} },
  { label: 'Part 1: Vocabulary', field: 'score', value_handler: (model)=>{
    return `Score: ${model.score.vocab}/${model.max_score.vocab} \n Level: ${english_level_opts[model.user_english_level.vocab]}`
  }, styleMethod: ()=>{return {'white-space': 'pre'}} }, 
  { label: 'Part 2: Listening', field: 'score', value_handler: (model)=>{
    return `Score: ${model.score.listening}/${model.max_score.listening} \n Level: ${english_level_opts[model.user_english_level.listening]}`
  }, styleMethod: ()=>{return {'white-space': 'pre'}} },
  { label: 'Part 2: Speaking', field: 'score', value_handler: (model)=>{
    return `Score: ${model.score.spoken_interaction.pronunciation + model.score.spoken_interaction.interaction}/${model.max_score.spoken_interaction.pronunciation + model.max_score.spoken_interaction.interaction} \n Level: ${english_level_opts[model.user_english_level.speaking]}`
  }, styleMethod: ()=>{return {'white-space': 'pre'}} },
  { label: 'Overall Result', field: 'score', value_handler: (model)=>{
    return `Score: ${model.total_score}/${model.total_max_score} \n Level: ${english_level_opts[model.user_english_level.all]}`
  }, styleMethod: ()=>{return {'white-space': 'pre'}} },
];

const learning_summary_fields = [
  {label: 'Skill', field: 'skill'},
  {label: 'Vocabulary', field: 'vocab'},
  {label: 'Listening', field: 'listening'},
  {label: 'Speaking', field: 'speaking'},
  {label: 'Total', field: 'total'}
]
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: null,
      user_products: null,
      login_devices: null,
      isUpdating: false,
      isDeleting: false,
      user_learn_record: null,
      is_recalculating: false,
      isEditing: false,
      isLoading: false,
      entryTestResult: null,
      exam_table_fields,
      learning_summary_fields
    };
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "User List",
          to: { name: "user-list" },
        },
      ];
      items.push({ text: "User Detail", active: true });
      return items;
    },
    userStatus() {
      let status = STATUS.find(item => item.value === this.user.status);
      return status || { text: 'unknown' };
    },
    userData() {
      return this.$store.getters['auth/userData']
    },
    LANGUAGE_OPTIONS(){
      return this.$store.getters['language/language_options']
    }
  },
  async created() {
    this.get();
    this.getUserLearningSummary();
  },
  methods: {
    async get() {
      let response = await service.get({ id: this.userId });
      if (response) {
        this.user = response.user;
        this.user_products = response.user_products;
        this.login_devices = response.login_devices;
        this.userEmail = this.user.email;
      }
    },
    async getRecord(){
      this.is_recalculating = true;
      let response = await service.getUserRecord({ user_id: this.userId});
      if (response){
        this.user_learn_record = response.user_summary
      }
      this.is_recalculating = false;
    },

    editAble() {
      this.isEditing = !this.isEditing
    },

    async updateUserEmail() {
      let validation = await this.$refs.updateEmailForm.validate();
      if (validation){
        this.isLoading = true
        const response = await service.updateUserEmail({ id: this.userId, email: this.userEmail, current_email: this.user.email })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated email!' })
          this.get();
          this.isEdittingEmail = false;
          this.userEmail = null;
        }
        this.isLoading = false
      }
    },

    async suspendUser() {
      this.isLoading = true
      const response = await service.suspend({ id: this.userId })
      if (response) {
        this.get()
      }
      this.isLoading = false
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getLoginDevice(device) {
      if (!device || !device.info) {
        return ''
      }
      if (device.info[0] !== '{') {
        return device.info
      }
      let split = device.info.split("brand':'")[1]
      split = split.slice(0,split.length - 2).replace("','os':'", ', ')

      return split
    },
    async updateUserPassword() {
      let validation = await this.$refs.updatePasswordForm.validate();
      if (validation){
        this.isLoading = true
        const response = await service.updateUserPassword({ email: this.userEmail, new_password: this.userPassword })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated password!' })
          this.get();
          this.isEdittingPassword = false;
          this.userPassword = null;
        }
        this.isLoading = false;
      }
    },
    async updateTeacherDescription(){
      const response = await service.updateTeacherDescription({ teacher_id: this.user._id, teacher_description: this.user.teacher_description })
      if (response.type === 'SUCCESS') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated!' })
      }
      else {
        this.$store.dispatch('pushErrorNotify', { text: 'Error!' })
      }
    },
    async getEntryTestResult() {
      let response = await service.getEntryTestResult({
        user_id: this.userId
      });
      let list = []; let total = 0;
      if (response) {
        list = response.records;
        english_level_opts = response.meta['ENGLISH_LEVEL_STRING']
      }
      return { list, total }
    },
    async getUserLearningSummary(){
      let response = await service.getUserLearningSummary({
        user_id: this.userId
      });
      let list = []
      if (response){
        let {learned_lessons, pass_lessons} = response;
        let learned = {
          skill: 'Total Learned Lessons',
          vocab: learned_lessons.find(item => item.type === 3)?.lessons || 0,
          listening: learned_lessons.find(item => item.type === 2)?.lessons || 0,
          speaking: learned_lessons.find(item => item.type === 1)?.lessons || 0,
          total: learned_lessons.reduce((total,item)=>{return total + item.lessons}, 0)
        }
        list.push(learned);
        let passed = {
          skill: 'Total Pass Lessons',
          vocab: pass_lessons.find(item => item.type === 3)?.lessons || 0,
          listening: pass_lessons.find(item => item.type === 2)?.lessons || 0,
          speaking: pass_lessons.find(item => item.type === 1)?.lessons || 0,
          total: pass_lessons.reduce((total,item)=>{return total + item.lessons}, 0)
        }
        list.push({
          ...passed,
          vocab: `${passed.vocab} (${learned.vocab ? this.getPercentage(passed.vocab, learned.vocab, 2) : 0}%)`,
          listening: `${passed.listening} (${learned.listening ? this.getPercentage(passed.listening, learned.listening, 2) : 0}%)`,
          speaking: `${passed.speaking} (${learned.speaking ? this.getPercentage(passed.speaking, learned.speaking, 2) : 0}%)`,
          total: `${passed.total} (${learned.total ? this.getPercentage(passed.total, learned.total, 2) : 0}%)`,
        })
      }
      return {list, total: 0}
    },
    getPercentage(x, y, to_fixed){
      return ((x / y) * 100).toFixed(to_fixed)
    }
  },
};
</script>
